.workflows {
  padding-top: 60px;

  &__head {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
  }

  &__h1 {
    text-align: center;
    letter-spacing: 0.2px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  &__text {
    line-height: 1.5;
    color: var(--dark-gray);
    margin-top: 10px;
    margin-bottom: 10px;
    &_center {
      text-align: center;
    }
  }

  &__algorithms-form {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;

    .Select-control,
    .Select-placeholder {
      height: 40px;
    }

    .Select-value-label,
    .Select-placeholder {
      line-height: 40px;
    }
  }
}

.algorithm {
  &__container {
    max-width: 1680px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
    padding-bottom: 30px;
    box-sizing: border-box;
  }

  &__wrap {
    background-color: var(--main-gray);
  }
}

.algorithm-form {
  display: flex;
  justify-content: space-around;

  &__info {
    width: 400px;
  }

  &__block {
    width: 400px;
  }

  &__input {
    input {
      box-shadow: none;
      border-radius: 4px;
      height: 40px;
      
      &::placeholder {
        color: #aaa;
      }
    }

    .Select-control {
      border: none;
      height: 40px;
      border-radius: 4px;

      .Select-value,
      .Select-placeholder {
        line-height: 40px;
      }
    }
  }

  &__input,
  &__radio {
    padding-bottom: 15px;
  }

  &__buttons-wrap {
    display: flex;
    justify-content: center;
  }

  &__btn {
    cursor: pointer;
    box-shadow: none;
    border-radius: 4px;
    font-size: 16px;
    width: 190px;
    height: 40px;
    transition: opacity .2s ease-in;
    background-color: transparent;
    color: var(--primary-medium);
    border: 1px solid var(--primary-medium);
    letter-spacing: 0.3px;
    font-weight: 400;

    &:hover {
      color: #ffffff;
      background-color: var(--primary-medium);
    }

    &_light {
      color: #000000;
      border: 1px solid var(--main-border-color);

      &:hover {
        color: #000000;
        background-color: var(--main-border-color);
      }
    }

    &_disabled {
      opacity: 0.5;
      cursor: auto;
      pointer-events: none;
    }
  }
}

.algorithm-table {
  &__error {
    color: var(--light-dark-gray);
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    padding: 15px 0;
  }
}

.algorithm-antibodies {
  width: 1080px;
  min-width: 1080px;
}

.hexelect-th {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;

  &_br {
    border-right: 1px solid #e0e0e0;
  }

  span {
    white-space: normal;
  }
}

.hexelect-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1000px;

  &__wrap {
    width: 100%;
    overflow: auto;
  }

  &__boxplot {
    overflow: auto;
  }
}

.chart-icons-wrap {
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chart-icon {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.1s ease-in;

  &:hover {
    opacity: 1;
  }

  &:after,
  &:before,
  span {
    display: block;
    width: 2px;
    background: rgba(126, 70, 174, 0.3);
    border: 2px solid var(--primary-medium);
    margin: 1px;
  }

  &:after,
  &:before {
    content: '';
  }

  &:before {
    height: 8px;
  }

  &:after {
    height: 12px;
  }

  span {
    height: 14px;
    box-sizing: content-box;
  }

  &_boxplot {
    align-items: center;

    &:before {
      height: 12px;
      border: 2px solid var(--secondary-active-medium);
    }

    &:after {
      height: 12px;
      border: 2px solid var(--secondary-active-medium);
    }

    span {
      height: 8px;
      border: 2px solid var(--secondary-active-medium);
    }
  }
}

.table-wrap {
  position: relative;

  &__cell-row_chart {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.violin-chart__wrap {
  width: 100%;
  overflow: auto;
  position: relative;
}

.modal-content.target-candidates-pairs-charts {
  width: 80vw;
  justify-content: flex-start;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] {
  -moz-appearance: textfield;
}

.input.input-text{
  transition: 0.1s;
}

.target-candidates-pairs-chart .target-candidates-charts {
  width: 100%;
}
