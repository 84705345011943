.sets-management {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  padding-top: 60px;
}
.sets-management__container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.set{
  height: 240px;
  width: 200px;
  margin: 0 100px;
  text-align: center;
  -webkit-transform: rotate(90deg) translateX(220px);
  transform: rotate(90deg) translateX(220px);
  -webkit-transform-origin: right top;
  transform-origin: right top;
}
.set-top-bar,
.set-bottom-bar{
  color: white;
  text-align: left;
  padding: 5px 0;
  min-height: 30px;
  cursor: default;
}
.set-bottom-bar{
  padding-top: 15px;
}
.set-blank{
  padding-top: 36%;
}
.set-card{
  cursor: pointer;
  background: white;
  border: 1px solid #ddd;
}
.set-content{
  color: var(--dark-gray);
  height: 90%;
}
.set-bottom-bar-name{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sets-info{
  flex: 1;
  overflow: auto;
}

.sets-info .ReactVirtualized__Table__rowColumn:not(:last-child) {
  border-right: none;
}

.sets-info .ReactVirtualized__Table__row {
  background-color: #F2F2F2;
  color: var(--dark-gray);
}

.sets-info .title-section {
  display: flex;
  align-items: center;
  width: 100%;
}

.sets-info .title-section:hover svg{
  fill: var(--secondary-active-medium) !important;
  transition: 0.5s;
}

.sets-info .title-section div{
  display: inline-block;
  vertical-align: middle;
}

.sets-info .title-section .title-section-name{
  padding-left: 10px;
  width: calc(100% - 30px);
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--primary-medium);
}

.sets-info .tag-section {
  padding-left: 10px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sets-info .ReactVirtualized__Table__headerColumn {
  text-align: left;
  padding-left: 10px;
}

.sets-info .header-input{
  width: 55%;
  margin-left: 5px;
}

.set-concepts-wrapper{
  width: 100%;
}
.set-concepts-list{
  width: 70%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.set-concepts-list-amount{
  width: 20%;
  text-align: center;
}
.set-concepts-list.set-concept-list-wide{
  white-space: normal;
  width: 90%;
  max-height: 200px;
  overflow: auto;
}
.set-concepts-list-controls,
.set-concepts-list-amount,
.set-concepts-list {
  display: inline-block;
  vertical-align: middle;
}
.set-concepts-list-controls{
  font-size: 22px;
  cursor: pointer;
}

.set-card-circle-container {
  height: 175px;
  position: relative;
}
.set-card-circle{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 20px;
}
.set-card-circle-small{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
}
.set-card-circle-small-l{
  width: 65px;
  height: 65px;
  border-radius: 50%;
  line-height: 65px;
}
.set-card-circle-medium{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  line-height: 80px;
}
.set-card-circle-large{
  width: 95px;
  height: 95px;
  border-radius: 50%;
  line-height: 95px;
}

.title-section-name.set-title{
  cursor: pointer;
}

.header-remark{
  font-size: 11px;
  font-weight: initial;
  font-style: italic;
}

.sets-info {
  .ReactVirtualized__Table__headerRow {
    background-color: #fff;
    border-top: none;
  }

  .header-section .input {
    border-radius: var(--border-radius);
    background: var(--background-light-color);
    box-shadow: none;
  }
}


.table-wrap__cell--left {
  margin-left: auto;
  text-align: left;
}

.my-projects-button-top-20 {
  margin-left: 20px;
}

.my-projects-button-top-10 {
  margin-left: 10px;
}
